import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";

const AwardBanner = () => {
    return (
        <>
            <div className="award_banner">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="appoint_left">
                                <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Award and Recognition </p>
                            </div>

                            <div className="gallery_head text-white">
                                <h1 className='pt-5'>Award And  <br /> Recognition</h1>
                                <p className='pt-2'>Celebrating excellence and honoring achievements that inspire success.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default AwardBanner;
