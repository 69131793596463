import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LiaGreaterThanSolid } from 'react-icons/lia';

export default function Privacybanner() {
    return (
        <div>
            <div className="privacy_banner">
                <Container>
                    <div className="appoint_left">
                        <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Privacy Policy </p>
                    </div>

                    <div className="gallery_head text-white">
                        <h1 className='pt-5'>At Sharva, your privacy is of supreme<br /> importance to us</h1>
                        <p className='pt-2'>We are dedicated to protection your <br />
                            online secrecy and ensuring the secure treatment of any info you share with us over the internet.</p>
                    </div>
                </Container>
            </div>
        </div>
    )
}
