import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../home.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiOutlinePlayCircle } from "react-icons/ai";
import banner_img from '../../../assets/bannerlogo.png'
import banner_img1 from '../../../assets/banner_img1.png'
import banner_img2 from '../../../assets/banner_img2.png'

const Banner = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: true
    };


    return (
        <>
            <div className="banner">
                <Container>
                    <Slider {...settings}>
                        <div className='client_test'>
                            <Row>
                                <Col md={6}>
                                    <div className="banner_left">
                                        <p className='lets'>Your Journey Begins Here</p>
                                        <div className="banner_head pt-4">
                                            <h1>Welcome to <br /> Sharva  Clinic: Your Path  to <br /> Health and Beauty </h1>
                                        </div> 
                                        <div className="banner_p pt-4">
                                            <p>Where Expertise Meets Care: Discover Comprehensive Medical Excellence and Aesthetic Transformation.</p>
                                        </div>
                                        <div className="banner_btn d-flex">
                                            <a href="#appoint"><Button className='btnbtn'>Get Started</Button></a>
                                            <a href="#"><AiOutlinePlayCircle className='play_icon' /></a>
                                            <p>Watch Intro</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="banner_img sharva_banner">
                                        <img src={banner_img} alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='client_test'>
                            <Row>
                                <Col md={6}>
                                    <div className="banner_left">
                                        <p className='lets'>Sculpting Beauty</p>
                                        <div className="banner_head pt-4">
                                            <h1>Crafting Timeless Elegance: Maxillofacial Aesthetic at Its Finest</h1>
                                        </div>
                                        <div className="banner_p pt-4">
                                            <p>Unveil Your True Radiance. Enhance Features, Elevate Confidence.</p>
                                        </div>
                                        <div className="banner_btn d-flex">
                                            <Button className='btnbtn'>Get Started</Button>
                                            <a href="#"><AiOutlinePlayCircle className='play_icon' /></a>
                                            <p>Watch Intro</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="banner_img">
                                        <img src={banner_img1} alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='client_test mb-5'>
                            <Row>
                                <Col md={6}>
                                    <div className="banner_left">
                                        <p className='lets'>Breathing Freely</p>
                                        <div className="banner_head pt-4">
                                            <h1>Breath of Vitality: Pulmonary Excellence for Optimal Respiratory Wellness</h1>
                                        </div>
                                        <div className="banner_p pt-4">
                                            <p>Breathe Easy, Embrace Life. Expert Care for Your Respiratory Health."</p>
                                        </div>
                                        <div className="banner_btn d-flex">
                                            <Button className='btnbtn'>Get Started</Button>
                                            <a href="#"><AiOutlinePlayCircle className='play_icon' /></a>
                                            <p>Watch Intro</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="banner_img">
                                        <img src={banner_img2} alt="" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Slider>
                </Container>
            </div >
        </>
    );
}

export default Banner;
