import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import 'react-tabs/style/react-tabs.css';
import { Smedia } from './smedia';
import { Gmedia } from './gmedia';
import { Clinic } from './clinic';
import { Machine } from './machine';
import { Customer_review } from './customer_review';

const Gallerytabs = () => {
    const [file, setFile] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);

    // Function to handle video play state
    const handleVideoPlay = (index) => {
        setActiveIndex(index);
    };

    // Function to reset video play state
    const resetVideoState = () => {
        setActiveIndex(null);
    };

    return (
        <>
            <div className="gallery_tabs">
                <Container>
                    <p className='py-5'>Immerse yourself in a visual narrative that showcases the artistry of our procedures, the <br />
                        dedication of our doctors, and the radiant results that our patients proudly embrace.</p>
                    <Tabs>
                        <TabList>
                            <Tab>Clinic</Tab>
                            <Tab>Equipments</Tab>
                            <Tab>Dr. Shweta Mishra</Tab>
                            <Tab>Dr. Gaurav Pandey</Tab>
                            <Tab>Customer Reviews</Tab>
                            {/* <Tab>Achievements</Tab> */}
                        </TabList>
                        <div className="sub_photos">
                            <TabPanel>
                                <Container>
                                    <div className="media-container">
                                        {
                                            Clinic.map((file, index) => (
                                                <div className="media" key={index} onClick={() => setFile(file)}>
                                                    {
                                                        <img src={file.url} alt="" />
                                                    }
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <div className="popup-media" style={{ display: file ? 'block' : 'none' }}>
                                        <span onClick={() => setFile(null)}>&times;</span>
                                        {
                                            <img src={file?.url} alt="" />
                                        }
                                    </div>
                                </Container>
                            </TabPanel>
                            <TabPanel>
                                <Container>
                                    <div className="media-container">
                                        {
                                            Machine.map((file, index) => (
                                                <div className="media" key={index} onClick={() => setFile(file)}>
                                                    {
                                                        <img src={file.url} alt="" />
                                                    }
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <div className="popup-media" style={{ display: file ? 'block' : 'none' }}>
                                        <span onClick={() => setFile(null)}>&times;</span>
                                        {
                                            <img src={file?.url} alt="" />
                                        }
                                    </div>
                                </Container>
                            </TabPanel>
                            <TabPanel>
                                <Container>
                                    <div className="media-container">
                                        {
                                            Smedia.map((file, index) => (
                                                <div className="media" key={index} onClick={() => setFile(file)}>
                                                    {
                                                        <img src={file.url} alt="" />
                                                    }
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <div className="popup-media" style={{ display: file ? 'block' : 'none' }}>
                                        <span onClick={() => setFile(null)}>&times;</span>
                                        {
                                            <img src={file?.url} alt="" />
                                        }
                                    </div>
                                </Container>
                            </TabPanel>
                            <TabPanel>
                                <Container>
                                    <div className="media-container">
                                        {
                                            Gmedia.map((file, index) => (
                                                <div className="media" key={index} onClick={() => setFile(file)}>
                                                    {
                                                        <img src={file.url} alt="" />
                                                    }
                                                </div>

                                            ))
                                        }
                                    </div>
                                    <div className="popup-media" style={{ display: file ? 'block' : 'none' }}>
                                        <span onClick={() => setFile(null)}>&times;</span>
                                        {
                                            <img src={file?.url} alt="" />
                                        }
                                    </div>
                                </Container>
                            </TabPanel>

                            <TabPanel>
                                <Container>
                                    <div className="media-container">
                                        {Customer_review.map((item, index) => (
                                            <div className="media" key={index} onClick={() => handleVideoPlay(index)}>
                                                {item.type === 'image' ? (
                                                    <img src={item.url} alt="" />
                                                ) : (
                                                    <ReactPlayer
                                                        url={item.url}
                                                        controls
                                                        width="100%"
                                                        height="40vh"
                                                        playing={activeIndex === index}
                                                        onEnded={resetVideoState}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                    <div className="popup-media" style={{ display: activeIndex !== null ? 'block' : 'none' }}>
                                        <span onClick={resetVideoState}>&times;</span>
                                        {activeIndex !== null && Customer_review[activeIndex].type === 'image' ? (
                                            <img src={Customer_review[activeIndex].url} alt="" />
                                        ) : (
                                            <ReactPlayer
                                                url={activeIndex !== null ? Customer_review[activeIndex].url : null}
                                                controls
                                                width="100%"
                                                height="100vh"
                                                playing={activeIndex !== null}
                                                onEnded={resetVideoState}
                                            />
                                        )}
                                    </div>
                                </Container>
                            </TabPanel>
                        </div>
                    </Tabs>
                </Container>
            </div >
        </>
    );
}

export default Gallerytabs;

