import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './component/home/home';
import Footer from './component/Footer/footer';
import About from './component/about/about';
import Gallery from './component/gallery/gallery';
import Contact from './component/contact/Contact';
import Doctor from './component/Doctor/Doctor';
import Services from './component/Services/Services';
import Makeappointment from './component/home/make_appointment/makeappointment';
import Gservices from './component/Services/Gsrvices';
import Privacy from './component/privacy_policy/Privacy';
import Award from './component/award/Award';
// import Popup from './component/popup/Popup';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        {/* <Popup /> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/award" element={<Award />} />
            <Route path="/doctor" element={<Doctor />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/services/:section" element={<Services />} />
            <Route path="/gservices/:section" element={<Gservices />} />
            <Route path="/make/:appoint" element={<Home />} />
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </HelmetProvider>
  );
}
export default App;
