import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

export default function Privacydetails() {
    return (
        <div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="privacy_head my-5">
                            <h1>Privacy Policy</h1>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <div className="privacy_details">
                        <h3>Collection of Private Information</h3>
                        <p>At Sharva, we respect your privacy and offer you the option to visit our website without
                            revealing any personal information. However, in certain instances, we may require you to
                            provide specific details, such as your email address, to subscribe to our newsletter or to schedule
                            an appointment. Rest assured that we handle this information with the utmost care.</p>
                        <br /><br />

                        <h3>Third-Party Websites</h3>
                        <p>Our website may contain links to third-party websites. It&#39;s important to note that Sharva
                            Aesthetics does not control the privacy policies and practices of these external sites. We
                            encourage you to review the privacy policies of any third-party websites you visit, as they may
                            have different policies governing the handling of personal information.</p>
                        <br /><br />
                        <h3>IP Addresses</h3>
                        <p>We collect and log the IP addresses of all visitors to our website. An IP address is a unique
                            numerical identifier assigned to your computer when you access the internet. This information
                            helps us administer our system and gather aggregate data about how our site is used. While we
                            may share this aggregate information with advertisers, sponsors, and other businesses, we do not
                            link IP addresses to personally identifiable records. However, we may use IP address information
                            to personally identify you when necessary to enforce our legal rights or comply with law
                            enforcement authorities.</p>

                        <br /><br />

                        <h3>Newsletter Subscription</h3>
                        <p>To subscribe to our newsletter, we may require your email address. We want to emphasize that
                            we do not share your email address with third parties.</p>
                        <br /><br />

                        <h3>Use of Cookies</h3>
                        <p>Like many other websites, Sharva Aesthetics uses cookies to collect information about the
                            sources of our site traffic. Cookies are small pieces of data stored on your computer&#39;s hard drive
                            when you visit a website. While these cookies help us understand our site&#39;s traffic patterns, they
                            do not allow us to personally identify you. Most web browsers automatically accept cookies, but
                            you have the option to modify security settings to approve or reject cookies on a case-by-case
                            basis. Please note that our site requires both cookies and JavaScript to be enabled, and disabling
                            cookies may affect certain functionalities.</p>

                        <br /><br />

                        <h3>Health Tools Information</h3>
                        <p>Some health tools on our site may require you to provide personal information, such as answers
                            to questions about your health status. This information is used solely to calculate risk and is
                            neither stored nor shared with others.</p>

                        <br /><br />

                        <h3>Surveys</h3>
                        <p>Sharva Aesthetics may request your participation in surveys to better understand user needs and
                            gather information about healthcare trends. We may share survey data with third parties that
                            provide data management services for our site. However, these third parties are obligated to keep
                            survey data confidential. Survey information may also be shared in an aggregated, de-identified
                            form with our business partners.</p>

                        <br /><br />

                        <h3>Data Security</h3>
                        <p>We take data security seriously. All information you enter on our site is encrypted with secure
                            server software (SSL) during transmission over the internet. Once your data reaches our servers,
                            it is protected by state-of-the-art security software. Additionally, we de-identify all data before
                            using it for aggregate reporting.</p>

                        <br /><br />

                        <h3>Sharing Information with Third Parties</h3>
                        <p>Except as mentioned for surveys, Sharva Aesthetics does not provide third parties access to your
                            IP address and email address. We may provide third parties with aggregate statistics about our
                            visitors, traffic patterns, and site-related information. However, this data is shared in an
                            aggregated, non-identifying form.</p>

                        <br /><br />
                        <h3>E-mail Communications</h3>
                        <p>When you sign up for our newsletter or use our site&#39;s email links to communicate with us, your
                            email communication may be shared with a relevant representative or agent to address your
                            inquiry. We strive to respond promptly to your messages, and once addressed, your
                            communication is either discarded or archived, depending on the nature of the inquiry. Please
                            note that email communication via our site may not be completely secure and confidential. If
                            privacy is a concern, please consider alternative means of communication.</p>

                        <br /><br />

                        <h3>Changes to Our Privacy Policy</h3>
                        <p>We may periodically update this Privacy Policy. Any changes will be indicated by the &quot;updated&quot;
                            date next to the Privacy Policy link at the bottom of our website pages. We encourage you to
                            review the policy whenever you visit our site to stay informed about how we protect your
                            information.</p>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
