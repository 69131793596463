import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import test1 from '../../../assets/test1.png'
import test2 from '../../../assets/test2.png'
import test3 from '../../../assets/test3.png'
import test4 from '../../../assets/test4.png'
import test5 from '../../../assets/test5.png'
import test6 from '../../../assets/test6.png'
import customer_review1 from '../../../assets/reviews/customer_review1.mp4'

const Testimonial = () => {

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };


    return (
        <>
            <div className="testimonial my-5 pb-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='Button_healthy mb-5'>
                                <span className='lets'>Testimonial</span>
                            </div>
                            <div className="main_head mt-5">
                                <h2>Words of Confidence and Trust</h2>
                            </div>
                            <div className="main_p mt-3">
                                <p>Read what our valued patients have to say about their experience and the <br />exceptional care they received.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="testimonial_slider">
                        <Slider {...settings}>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test1} alt="" />
                                    <h6 className='client_name pt-3'>alka jain</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Very good clinic for cosmetic surgery <br /> and hydra facial for womens.  Doctor's and staff are really good. All skin treatment like mole removal <br /> and acne removal. Good location in Rohini, New Delhi.</p>
                                </div>
                            </div>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test2} alt="" />
                                    <h6 className='client_name pt-3'>atishay jain (Inspiring Nomads)</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>I would say that Dr Shweta is best cosmetic surgeon In New Delhi, she is not only a doctor but a very good human being too. Welcomed and guided us for the treatment of skin. I consulted for scar that i got on my face due to excessive heat. They are permanently off after 1-2 months of applying the prescribed serums and treatment.

                                        My wife has also got her mole off from her face. It took only 5 minutes and it's gone. She is now taking sitting for laser hair removal for under arms, upper lips and side locks.

                                        Highly recommended skin doctor for all the skin & hair treatments.

                                        Ample parking and welcoming staff is a super plus.</p>
                                </div>
                            </div>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test3} alt="" />
                                    <h6 className='client_name pt-3'>Paras Jalwal</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Went there for my hair treatment <br /> and had really great experience by having painless and comfortable <br /> treatment as per my condition.</p>
                                </div>
                            </div>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test4} alt="" />
                                    <h6 className='client_name pt-3'>Reena mahalawat</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Had a really good experience over there, they <br /> had good machines, the doctor and staff was polite and the doctor <br /> made me feel comfortable throughout the procedure.</p>
                                </div>
                            </div>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test2} alt="" />
                                    <h6 className='client_name pt-3'>Astha Jain</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Dr Sweta Mishra - Exceptional cosmetic surgeon clinic in Delhi.
                                        From the friendly <br /> staff to the skilled surgeon, my experience was flawless.
                                        She explained me the procedure thoroughly, and the results are phenomenal.  The clinic's professionalism and care are commendable.  <br />Highly recommended for anyone seeking transformative cosmetic procedures or aesthetic surgeon.</p>
                                </div>
                            </div>
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test6} alt="" />
                                    <h6 className='client_name pt-3'>Yogesh Mishra</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Good experience, well trained <br /> staff and doctor is well knowledgable. <br /> Recommend it to everyone😀</p>
                                </div>
                            </div>
                            {/* end */}

                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test5} alt="" />
                                    <h6 className='client_name pt-3'>Shanu</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Went there for PRP treatment. It’s highly recommendable and Dr. Shweta is very nice and professional. Very much satisfied.</p>
                                </div>
                            </div>
                            {/* end */}

                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test6} alt="" />
                                    <h6 className='client_name pt-3'>Anita Choudhary</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>The doctors behaviour are very good and polite...😇</p>
                                </div>
                            </div>
                            {/* endss */}

                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test1} alt="" />
                                    <h6 className='client_name pt-3'>Mitanshi garg</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Sharva is indeed a very professional and adequately equipped clinic providing the best to your needs.</p>
                                </div>
                            </div>
                            {/* ends */}

                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test2} alt="" />
                                    <h6 className='client_name pt-3'>DR. HEMLATA YADAV</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Newer technologies <br />
                                        Honourable Doctor <br />
                                        Mam behaviour is very humble and decent too <br />
                                        All newer machines and technology available<br />
                                        Everyone must visit once and they will keep on going and join with mam for forever <br />
                                    </p>
                                </div>
                            </div>
                            {/* ends */}
                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test4} alt="" />
                                    <h6 className='client_name pt-3'>dj dhaval joshi</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Real happy on getting proper detailed PRP treatment for my hairs. <br />
                                        Thanks to dr shweta. Kudos
                                    </p>
                                </div>
                            </div>
                            {/*ends  */}

                            <div className='client_test'>
                                <div className="client_icon pt-3">
                                    <img src={test3} alt="" />
                                    <h6 className='client_name pt-3'>Baby Tripathi</h6>
                                    <p className='client_place'>Delhi India</p>
                                </div>
                                <div className="client_says">
                                    <p>Really Appreciable, my friend has respiratory problem and has shown
                                        Many doctor's so we heard about Dr Gaurav as well so we thought of trying there as well, and just after two sessions by Dr gaurav pandey there is a significant improvement, really happy by the treatment.
                                    </p>
                                </div>
                            </div>
                            {/* ends */}
                        </Slider>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Testimonial;
