import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Header/header';
import Banner from './Banner/banner';
import DocAbout from './doc_about/docAbout';
import Chooseus from './chooseus/chooseus';
import Steps from './steps/steps';
import Makeappointment from './make_appointment/makeappointment';
import Gallery from './Gallery/Gallery';
import Testimonial from './testimonial/testimonial';
import Whatsapp from '../Whatsapp/Whatsapp';

const Home = () => {

    const { appoint } = useParams();

    useEffect(() => {
        const targetSection = document.getElementById(appoint);
        if (targetSection) {
          targetSection.scrollIntoView({ behavior: 'smooth' });
        }
      }, [appoint]);
    
    return (
        <div>
            <Header/>
            <Banner/>
            <DocAbout/>
            <Chooseus/>
            <Steps/>
            <Makeappointment/>
            <Gallery/>
            <Testimonial/>
            <Whatsapp/>
        </div>
    );
}

export default Home;
