import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import doc_img from '../../../assets/doc_img.png'
import doc_img1 from '../../../assets/doc_img1.png'
import doc_img2 from '../../../assets/doc_img2.png'


const Doctorinfo = () => {
    return (
        <>
            <div className="doc_about mt-5 pt-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='Button_healthy'>
                                <span className='lets'>Doctor Details</span>
                            </div>
                        </Col>
                    </Row>
                    <div className="men_desc">
                        <Row>
                            <Col md={6}>
                                <div className="doc_img mt-5 pt-4">
                                    <img src={doc_img2} alt="" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="doc_details my-5 pt-5">
                                    <div className="doc_name">
                                        <h1>Dr. Gaurav Pandey</h1>
                                        <h3>Pulmonologist & Diabetologist</h3>
                                    </div>
                                    <div className="doc_edu pt-3">
                                        <p>MD Pulmonary Medicine(Chest Specialist), Dip. Geriatric Medicine(MAMC), FCC(Cardiology), CDM(Diabetes), CPMEC(Chest), Spl. Int. - Diabetes & Hypertension</p>
                                    </div>
                                    <div className="doc_content pt-3">
                                        <p>Dr. Gaurav Pandey is dedicated to delivering comprehensive patient-centric care, utilizing
                                            evidence-based practices and the latest research to ensure optimal outcomes. With over 9 years of
                                            enriched experience, he became the best allergy and immunology specialist in delhi and also he
                                            excels in managing diverse respiratory conditions, from asthma and COPD to sleep disorders and
                                            sinusitis.
                                            His expertise extends to diabetes and associated disorders, including diabetic neuropathy. Driven by
                                            a commitment to excellence, Dr. Pandey&#39;s tailored care plans and clinical insights empower your
                                            journey to respiratory and overall wellness.
                                        </p>
                                    </div>
                                    <div className="doc_btn pt-4">
                                        <a href="/make/appoint"><Button>Make Appointment</Button></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="lady_desc">
                        <Row>
                            <Col md={6}>
                                <div className="doc_img mt-5 pt-4">
                                    <img src={doc_img} alt="" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="doc_details my-5 pt-5">
                                    <div className="doc_name">
                                        <h1>Dr. Shweta Mishra</h1>
                                        <h3>Maxillofacial Aesthetic Surgeon</h3>
                                    </div>
                                    <div className="doc_edu pt-3">
                                        <p>MDS, FAM, Dip. In Aesthetic Medicine</p>
                                    </div>
                                    <div className="doc_content pt-3">
                                        <p>
                                            Dr. Shweta mishra is a Maxillofacial Aesthetic Surgeon, super-specialized in Facial Cosmetics, and Hair Transplantation. Experienced in dealing patients with varied complaints about Facial Rejuvenation, Hair Rejuvenation, Facial deformities, and pathologies. <br /><br />
                                            Her expertise includes procedures like Facial Plastic Procedures including Face Lifts, Rhinoplasty, Blepharoplasty, Otoplasty, Facial Liposuction, Jaw contouring, and Lip Enhancement. She is also experienced in Non-Surgical Facial Rejuvenation, including Botox, Dermal Fillers, and Non-Surgical Face Lifts using threads and devices like HIFU, Skin LASERS, and Chemical Peels. <br /><br />
                                            She is also experienced in resective, reconstructive, and rehabilitative procedures of the Head and Neck region including head and neck or facial fractures, head and neck pathologies, head and neck cancers, orthognathic surgeries, craniofacial syndromes and cleft lip etc
                                        </p>
                                    </div>
                                    <div className="doc_btn pt-4">
                                        <a href="/make/appoint"><Button>Make Appointment</Button></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="men_desc">
                        <Row>
                            <Col md={6}>
                                <div className="doc_img mt-5 pt-4">
                                    <img src={doc_img1} alt="" />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className="doc_details my-5 pt-5">
                                    <div className="doc_name">
                                        <h1>Dr Shubham Pandey</h1>
                                        <h3>Aesthetic Physician</h3>
                                    </div>
                                    <div className="doc_edu pt-3">
                                        <p>MBBS, FAM</p>
                                    </div>
                                    <div className="doc_content pt-3">
                                        <p>With a keen focus on aesthetic medicine, Dr. Shubham Pandey, a dedicated General Physician, specializes in revitalizing aging or damaged skin. His expertise encompasses addressing wrinkles, volume loss, sagging, acne, rosacea, and pigmentation disorders like melasma. <br /><br />
                                            Offering minimally-invasive procedures including Botulinum toxin injections, dermal fillers, chemical peels, microblading, and aesthetic lasers, Dr. Pandey ensures patients attain desired and exceptional outcomes. <br /><br /> His commitment to enhancing well-being through tailored treatments makes him a trusted choice for achieving your aesthetic goals.
                                        </p>
                                    </div>
                                    <div className="doc_btn pt-4">
                                        <a href="/make/appoint"><Button>Make Appointment</Button></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Doctorinfo;
