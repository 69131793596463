import React, { useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';  // Import Yup for validation
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact_icon1 from '../../../assets/contact_icon1.png'
import contact_icon2 from '../../../assets/contact_icon2.png';


const Contactdata = () => {
    const form = useRef();
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        subject: Yup.string().required('Subject is required'),
        message: Yup.string().required('Message is required'),
    });

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await emailjs.sendForm('service_o1k0dmk', 'template_85akchr', form.current, 'AjMme9S_jmOB-oXL_');
            toast.success("Inquiry successfully sent!"); // Show success toast
            resetForm(); // Use Formik's resetForm function to reset the form fields
        } catch (error) {
            console.error(error); // Log the entire error object
            toast.error("An error occurred while making the Inquiry."); // Show error toast
        } finally {
            setSubmitting(false);
        }
    };
    return (
        <>
            <div className="contact_data my-5">
            <ToastContainer  position="bottom-right"/>
                <Container>
                    <div className='Button_healthy1 mt-5 mb-4 pt-5'>
                        <span className='lets'>Contact</span>
                    </div>
                    <Row>
                        <Col md={6}>
                            <div className="contact_left">
                                <h2 className='pt-2'>Your Wellness, Our Priority </h2>
                                <p className='contact_p pt-2'>Discover personalized solutions tailored to your health and aesthetic aspirations. Your journey to well-being begins with us.</p>

                                <div className="contact_left_card d-flex justify-content-between">
                                    <div className="contact_text">
                                        <h5>Consultation for Free</h5>
                                        <p>+91 8595941055</p>
                                    </div>

                                    <div className="contact_icon">
                                        <img src={contact_icon1} alt="contact_icon1" />
                                    </div>
                                </div>

                                <div className="contact_right_card d-flex justify-content-start mt-5">
                                    <div className="contact_icon">
                                        <img src={contact_icon2} alt="contact_icon1" />
                                    </div>
                                    <div className="contact_text">
                                        <h5>Email Us For More</h5>
                                        <p>sharvaclinic@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="contact_page_form">
                                <Formik
                                    initialValues={{
                                        name: '',
                                        email: '',
                                        subject: '',
                                        message: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ handleSubmit, isSubmitting }) => (
                                        <Form ref={form} onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3" controlId="formBasicName">
                                                <Field type="text" name="name" placeholder="Name" className="form-control" />
                                                <ErrorMessage name="name" component="div" className="error-message" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Field type="text" name="email" placeholder="Your Email" className="form-control" />
                                                <ErrorMessage name="email" component="div" className="error-message" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicSubject">
                                                <Field type="text" name="subject" placeholder="Your Subject" className="form-control" />
                                                <ErrorMessage name="subject" component="div" className="error-message" />
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicMessage">
                                                <Field as="textarea" name="message" rows={3} placeholder="Your Message" className="form-control" />
                                                <ErrorMessage name="message" component="div" className="error-message" />
                                            </Form.Group>

                                            <div className="doc_btn pt-3">
                                                <Button variant="primary" type="submit" className='doc_btn' disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <div className="contact_addr_map my-5">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1806782.7598153981!2d75.09330147971953!3d27.813692813172104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d07f4f58fc55b%3A0x1e9d9fc6af1a36ac!2sSharva%20clinic!5e0!3m2!1sen!2sin!4v1691395996417!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Contactdata;

