import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import step1 from '../../../assets/step1.png'
import step2 from '../../../assets/step2.png'
import step3 from '../../../assets/step3.png'
import step4 from '../../../assets/step4.png'
const Steps = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='Button_healthy'>
                            <span className='lets'>Effortless Treatment Journey</span>
                        </div>
                        <div className="main_head mt-5">
                            <h2>Your path to renewal simplified</h2>
                        </div>
                        <div className="main_p mt-3">
                            <p> Choose, Book, Pay, and Enjoy your <br/> treatment journey.</p>
                        </div>
                    </Col>
                </Row>
                <div className="steps my-5">
                    <Row>
                        <Col md={3}>
                            <div className="step_card">
                                <div className="step_count">
                                    <p>01</p>
                                </div>
                                <div className="step_icon">
                                    <img src={step1} alt="" />
                                </div>
                                <div className="step_head pt-4">
                                    <h6>Select Service</h6>
                                </div>
                                <div className="step_content">
                                    <p>Choose your desired expertise from our specialized offerings, tailored to your needs. Begin your journey by selecting the service that aligns with your wellness goals.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="step_card">
                                <div className="step_count">
                                    <p>02</p>
                                </div>
                                <div className="step_icon">
                                    <img src={step2} alt="" />
                                </div>
                                <div className="step_head pt-4">
                                    <h6>Make an Appointment</h6>
                                </div>
                                <div className="step_content">
                                    <p>Book your consultation seamlessly, and our team will ensure a convenient schedule. Your health and comfort are paramount; schedule your appointment with ease and confidence.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="step_card">
                                <div className="step_count">
                                    <p>03</p>
                                </div>
                                <div className="step_icon">
                                    <img src={step3} alt="" />
                                </div>
                                <div className="step_head pt-4">
                                    <h6>Complete Payment</h6>
                                </div>
                                <div className="step_content">
                                    <p>Streamlined payment process to ensure a hassle-free experience. Enjoy a smooth and secure payment process, putting your focus on your well-being.</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="step_card">
                                <div className="step_count">
                                    <p>04</p>
                                </div>
                                <div className="step_icon">
                                    <img src={step4} alt="" />
                                </div>
                                <div className="step_head pt-4">
                                    <h6>Enjoy Your Treatment</h6>
                                </div>
                                <div className="step_content">
                                    <p>Relish the expert care and transformative experience, as we guide you toward rejuvenation. Immerse yourself in a fulfilling treatment journey, where your health and beauty thrive.</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default Steps;
