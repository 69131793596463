import React from 'react';
import './contact.css';
import Otherheader from '../Header/Otherheader';
import Contactbanner from './ContactBanner/Contactbanner';
import Contactdata from './Contact_data/Contactdata';
import Whatsapp from '../Whatsapp/Whatsapp';
import Header from '../Header/header';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
    return (
        <>
            <Helmet>
                <title>Contact us – Sharva Clinique</title>
                <meta
                    name="description"
                    content="Contact Sharva if you have any concerns, questions regarding Sharva Clinic,
                            Please fill the form given below."
                />
            </Helmet>
            <Header />
            {/* <Otherheader /> */}
            <Contactbanner />
            <Contactdata />
            <Whatsapp />
        </>
    )
}

export default Contact;
