export const Clinic = [
    {type: 'image', url: require('../../../assets/clinic/8.jpg')},
    {type: 'image', url: require('../../../assets/clinic/1.png')},
    {type: 'image', url: require('../../../assets/clinic/14.jpg')},
    {type: 'image', url: require('../../../assets/clinic/15.jpg')},
    {type: 'image', url: require('../../../assets/clinic/17.jpg')},
    {type: 'image', url: require('../../../assets/clinic/18.jpg')},
    {type: 'image', url: require('../../../assets/clinic/19.jpg')},
    {type: 'image', url: require('../../../assets/clinic/2.png')},
    {type: 'image', url: require('../../../assets/clinic/4.jpg')},
    {type: 'image', url: require('../../../assets/clinic/5.jpg')},
    {type: 'image', url: require('../../../assets/clinic/6.jpg')},
]