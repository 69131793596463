export const Smedia = [
    // {type: 'image', url: require('../../../assets/shweta_mishra/3.png')},
    {type: 'image', url: require('../../../assets/shweta_mishra/4.jpg')},
    {type: 'image', url: require('../../../assets/shweta_mishra/5.jpg')},
    {type: 'image', url: require('../../../assets/shweta_mishra/7.jpg')},
    {type: 'image', url: require('../../../assets/shweta_mishra/1.jpg')},
    {type: 'image', url: require('../../../assets/shweta_mishra/2.JPG')},
    {type: 'image', url: require('../../../assets/shweta_mishra/3.png')},
    {type: 'image', url: require('../../../assets/shweta_mishra/6.png')},
    {type: 'image', url: require('../../../assets/shweta_mishra/8.jpg')},
]