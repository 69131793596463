import React from 'react';
import Otherheader from '../Header/Otherheader'
import Gallerybanner from './gallery_banner/Gallerybanner';
import './gallery.css';
import Gallerytabs from './gallery_tabs/Gallerytabs';
import Afterbefore from './after_before/Afterbefore';
import Whatsapp from '../Whatsapp/Whatsapp';
import Header from '../Header/header';
import { Helmet } from 'react-helmet-async';


const Gallery = () => {
    return (
        <>

            <Helmet>
                <title>Gallery – Before After results of Sharva Clinique</title>
                <meta
                    name="description"
                    content="Enhace your skincare routine with personalised advice for healthy and rediant skin."
                />
            </Helmet>


            <Header />
            {/* <Otherheader /> */}
            <Gallerybanner />
            <Gallerytabs />
            <Afterbefore />
            <Whatsapp />
        </>
    );
}

export default Gallery;
