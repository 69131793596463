import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import choose1 from '../../../assets/choose1.png'
import choose2 from '../../../assets/choose2.png'
import choose3 from '../../../assets/choose3.png'
import choose4 from '../../../assets/choose4.png'
import choose5 from '../../../assets/choose5.png'
import choose6 from '../../../assets/choose6.png'
import choose7 from '../../../assets/choose7.png'
import choose8 from '../../../assets/choose8.png'
import choose9 from '../../../assets/choose9.png'

const Chooseus = () => {
    return (
        <>
            <div className="chooseus">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='Button_healthy'>
                                <span className='lets'>Why Choose Sharva Clinic? </span>
                            </div>
                            <div className="main_head mt-5">
                                <h2>Our Distinctive Advantages </h2>
                            </div>
                            <div className="main_p mt-3">
                                <p>Expertise, Compassion, and Excellence: Your Pathway to Optimal <br/> Health and Timeless Beauty.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="choose_us_points my-5">
                        <Row>
                            <Col md={4}>
                                <div className="choose_card1 my-3">
                                    <div className="choose_head">
                                        <h6>Facelifts & Contouring </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Sculpt and redefine your facial features, unveiling a refreshed, youthful allure. Experience the art of transformation with precision and confidence.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose1} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card2 my-3">
                                    <div className="choose_head">
                                        <h6>Lip Enhancement </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Achieve gracefully enhanced lips, adding allure and elegance to your smile. Rediscover your natural beauty with expert precision.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose2} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card3 my-3">
                                    <div className="choose_head">
                                        <h6>Dermal Fillers </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Restore youthful radiance, smooth wrinkles, and reveal a revitalized, natural glow. Experience rejuvenation with a gentle touch.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose3} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <div className="choose_card4 my-3">
                                    <div className="choose_head">
                                        <h6>Asthma Management </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Breathe freely and live fully with personalized care, ensuring optimal asthma control. Empower your respiratory health journey.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose4} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card5 my-3">
                                    <div className="choose_head">
                                        <h6>COPD Care </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Tailored strategies for enhanced respiratory wellness, empowering an active, vibrant life. Take charge of your lung health with expert guidance.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose5} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card6 my-3">
                                    <div className="choose_head">
                                        <h6>Sleep Disorders </h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Reclaim restful nights and revitalized mornings through expert solutions. Embrace tranquillity and renewed energy with specialized sleep care.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose6} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <div className="choose_card1 my-3">
                                    <div className="choose_head">
                                        <h6>Other Respiratory Diseases</h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Expert pulmonologists diagnose and treat various respiratory conditions, including lung infections, interstitial lung disease, and pulmonary <br /> fibrosis. </p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose7} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card2 my-3">
                                    <div className="choose_head">
                                        <h6>Allergy/Sinus</h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>Our pulmonologists address allergies and sinus issues, offering comprehensive care to relieve symptoms, improve breathing, and enhance overall respiratory health.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose8} alt="" />
                                    </div>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="choose_card3 my-3">
                                    <div className="choose_head">
                                        <h6>Diabetes</h6>
                                    </div>
                                    <div className="choose_p pt-3">
                                        <p>For diabetic patients, our pulmonologists provide specialized support, managing respiratory complications such as diabetic ketoacidosis and pulmonary infections.</p>
                                    </div>
                                    <div className="choose_icon">
                                        <img src={choose9} alt="" />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Chooseus;
