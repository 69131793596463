import React from 'react'
import Privacybanner from './privacy_banner/Privacybanner'
import Privacydetails from './privacy_details/Privacydetails'
import './Privacy.css';
import Otherheader from '../Header/Otherheader';

export default function Privacy() {
    return (
        <>
            <Otherheader />
            <Privacybanner />
            <Privacydetails />
        </>
    )
}
