import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import doc_img from '../../assets/doc_img.png'
import doc_img1 from '../../assets/doc_img1.png'
import doc1 from '../../assets/doc1.png'
import docsub1 from '../../assets/docsub1.png'
import docsub2 from '../../assets/about_img.png'
import { BsArrowRight } from "react-icons/bs";
import Otherheader from '../Header/Otherheader';
import Doctorinfo from './DoctorInfo/Doctorinfo';
import Doctorbanner from './doctorBanner/Doctorbanner';
import './doctor.css';
import Whatsapp from '../Whatsapp/Whatsapp';

const Doctor = () => {
    return (
        <>
        <Otherheader/>
        <Doctorbanner/>
        <Doctorinfo/>
        <Whatsapp/>
        </>
    );
}

export default Doctor;
