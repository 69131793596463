import React from 'react';
import AboutBanner from './aboutbanner/aboutBanner';
import Otherheader from '../Header/Otherheader';
import Doctorabout from './doctor_about/Doctorabout';
import Aboutdesc from './about_desc/Aboutdesc';
import Testimonial from '../home/testimonial/testimonial';
import Whatsapp from '../Whatsapp/Whatsapp';
import Header from '../Header/header';
import { Helmet } from 'react-helmet-async';


const About = () => {
    return (
        <>
            <Helmet>
                <title>About us – Sharva Clinique</title>
                <meta
                    name="description"
                    content="Sharva Clinique - A super-speciality centre devoted to Facial Aesthetics, Pulmonary Diseases, and Diabetes, offering advanced treatments and personalised care to your well-being."
                />
            </Helmet>

            <Header />
            {/* <Otherheader/> */}
            <AboutBanner />
            <Aboutdesc />
            <Doctorabout />
            <Testimonial />
            <Whatsapp />
        </>
    );
}

export default About;
