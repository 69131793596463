import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './footer.css';
import logo from '../../assets/sharva.png'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import { FiMapPin, FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
const Footer = () => {
    return (
        <>
            <div className="footer my-5">
                <footer>
                    <Container>
                        <Row>
                            <Col md={3}>
                                <div className="logo">
                                    <img src={logo} alt="" />
                                </div>
                                <div className="about mt-3">
                                    <p>We are a healthcare therapy provider focused on the diagnosis and treatment of disorders of the musculoskeletal.
                                    </p>
                                </div>

                                <div className="social_links">
                                    <a href=""><FaFacebookF className='social_icon' /></a>
                                    <a href=""><FaTwitter className='social_icon' /></a>
                                    <a href=""><FaLinkedinIn className='social_icon' /></a>
                                    <a href="https://www.instagram.com/sharvaclinic/"><FaInstagram className='social_icon' /></a>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="quick_link">
                                    <div className="head">
                                        <h5>Quick Links</h5>
                                    </div>
                                    <div className="links">
                                        <ul>
                                            <a href="/"><li>Home</li></a>
                                            <a href="/about"><li>About</li></a>
                                            <a href="/contact"><li>Contact</li></a>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="quick_link">
                                    <div className="head">
                                        <h5>Support</h5>
                                    </div>
                                    <div className="links">
                                        <ul>
                                            <a href="/privacy"><li>Privacy Policy</li></a>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="quick_link">
                                    <div className="head">
                                        <h5>Our Clinic</h5>
                                    </div>
                                    <div className="links">
                                        <Row>
                                            <Col md={2} xs={2}>
                                                <FiMapPin className='soc_icon' />
                                            </Col>
                                            <Col md={10} xs={10}>
                                                <ul>
                                                    <li> 226 ground floor, near Ryan int. School, Pocket 2, Sector 25, Rohini, New Delhi, Delhi, 110085 <br />Plot 63, first floor, Harsh Vihar , Pitampura , New Delhi</li>
                                                </ul>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={2} xs={2}>
                                                <BsTelephone className='soc_icon' />
                                            </Col>
                                            <Col md={10} xs={10}>
                                                <ul>
                                                    <li>+91 8595941055</li>
                                                    <li>+91 8586083877</li>
                                                    <li>+91 8290336633</li>
                                                </ul>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={2} xs={2}>
                                                <FiMail className='soc_icon' />
                                            </Col>
                                            <Col md={10} xs={10}>
                                                <ul>
                                                    <li>sharvaclinic@gmail.com</li>
                                                </ul>
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </div>
        </>
    );
}

export default Footer;
