import React from 'react'
import Header from '../Header/header'
import AwardBanner from './award/AwardBanner'
import AwardImages from './award/AwardImages'

const Award = () => {
    return (
        <div>
            <Header />
            <AwardBanner />
            <AwardImages />
        </div>
    )
}

export default Award
