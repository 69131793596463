import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import award1 from '../../../assets/award/award1.jpg'
import award2 from '../../../assets/award/award2.jpg'
import award3 from '../../../assets/award/award3.jpg'
import award4 from '../../../assets/award/award4.jpg'
import './Award.css';


const AwardImages = () => {
    return (
        <div>
            <Container>
                <Row>
                    <Col md={3}>
                        <img src={award1} alt='award and recognition' className='award1' />
                    </Col>
                    <Col md={3}>
                        <img src={award2} alt='award and recognition' className='award1' />
                    </Col>
                    <Col md={3}>
                        <img src={award3} alt='award and recognition' className='award2' />
                    </Col>
                    <Col md={3}>
                        <img src={award4} alt='award and recognition' className='award1' />
                    </Col>
                </Row>
                <br /><br />
            </Container>

        </div>
       
    )
}

export default AwardImages
