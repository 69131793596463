import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { BsFillCheckCircleFill } from "react-icons/bs";
import './Makeappointment.css';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const serviceOptions = [
    'Anti-Wrinkle Injection',
    'Dermal Fillers',
    'Injectable skin Boosters',
    'HydraFacial',
    'Chemical Peels',
    'MesoTherapy',
    'Skin Resurfacing Procedure',
    'Laser Hair Reduction',
    'Hair Re-Growth Treatment Therapy',
    'MicroDermadrasion',
    'Semi- Permanent Makeup',
    'Face Lift',
    'Eyelid Sugrery',
    'Neck Lift',
    'Brow Lift',
    'Chin Surgery',
    'Lip Surgery',
    'Haip Transplant',
    'Dimple Creation',
    'Ear Lube Repair',
    'Facial Fat Gratting',
    'Asthma',
    'COPD',
    'Allergies',
    'TB',
    'Cough and cold',
    'Chest infection',
    'Snoring',
    'Sleep disturbances',
    'Respiratory issues',
    'Sinusitis',
    'Eosinophilia',
    'Diabetes',
    'Diabetic neuropathy',
    'Hypertension',
    'ECG',
    'PFT',
    'SUGAR PROFILE'
    // Add more services as needed
];


const Makeappointment = () => {
    const [selectedService, setSelectedService] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(serviceOptions);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState({});
    const form = useRef();

    // Move the formikProps definition outside the return statement
    let formikProps;

    const resetForm = () => {
        setSelectedService('');
        setInputValue('');
        setShowDropdown(false);
        setSelectedDoctor(''); // Reset the selected doctor dropdown
        setName('');
        setEmail('');
        setPhoneNumber('');
        setSelectedDate('');
        setMessage('');
        setErrors({});
    };

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInputValue(value);

        const filtered = serviceOptions.filter((option) =>
            option.toLowerCase().startsWith(value.toLowerCase())
        );

        setFilteredOptions(filtered);

        setShowDropdown(true);
    };


    const handleSelectService = (service) => {
        formikProps.setFieldValue('selectedService', service);
        setInputValue(service);
        setShowDropdown(false);
    };

    const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            await emailjs.sendForm('service_o1k0dmk', 'template_jky46a8', form.current, 'AjMme9S_jmOB-oXL_');
            toast.success("Appointment has been successfully made!"); // Show success toast
            resetForm(); // Use Formik's resetForm function to reset the form fields
            setSelectedDoctor(''); // Reset the selected doctor dropdown
        } catch (error) {
            console.log(error.text);
            toast.error("An error occurred while making the appointment."); // Show error toast
        } finally {
            setSubmitting(false);
        }
    };




    return (
        <>
            <div className="appoint py-5" id="appoint">
                <ToastContainer position="bottom-right" />
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="appoint_left">
                                <p className='lets'>Let's Be Healthy</p>

                                <div className="appoint_left_head">
                                    <h2>Schedule Your Visit </h2>
                                </div>

                                <Row>
                                    <Col md={6}>
                                        <div className="appoint_part mt-4">
                                            <Row>
                                                <Col md={2} xs={2}>
                                                    <BsFillCheckCircleFill className='check_icon' />
                                                </Col>
                                                <Col md={10} xs={10}>
                                                    <div className="appoint_desc">
                                                        <div className="app_head">
                                                            <h5>Online Scheduling </h5>
                                                        </div>
                                                        <div className="app_p">
                                                            <p>Easily book your appointment anytime, anywhere</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-4'>
                                                <Col md={2} xs={2}>
                                                    <BsFillCheckCircleFill className='check_icon' />
                                                </Col>
                                                <Col md={10} xs={10}>
                                                    <div className="appoint_desc">
                                                        <div className="app_head">
                                                            <h5>Instant Confirmations </h5>
                                                        </div>
                                                        <div className="app_p">
                                                            <p>Receive immediate confirmation for your scheduled visit</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>

                                    <Col md={6}>
                                        <div className="appoint_part mt-4">
                                            <Row>
                                                <Col md={2} xs={2}>
                                                    <BsFillCheckCircleFill className='check_icon' />
                                                </Col>
                                                <Col md={10} xs={10}>
                                                    <div className="appoint_desc">
                                                        <div className="app_head">
                                                            <h5>Flexible Availability </h5>
                                                        </div>
                                                        <div className="app_p">
                                                            <p>Choose from a range of convenient appointment slots</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className='mt-4'>
                                                <Col md={2} xs={2}>
                                                    <BsFillCheckCircleFill className='check_icon' />
                                                </Col>
                                                <Col md={10} xs={10}>
                                                    <div className="appoint_desc">
                                                        <div className="app_head">
                                                            <h5>Effortless Rescheduling </h5>
                                                        </div>
                                                        <div className="app_p">
                                                            <p>Modify your appointment effortlessly if needed</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div className="appoint_part mt-4">
                                            <Row>
                                                <Col md={2} xs={2}>
                                                    <BsFillCheckCircleFill className='check_icon' />
                                                </Col>
                                                <Col md={10} xs={10}>
                                                    <div className="appoint_desc">
                                                        <div className="app_head">
                                                            <h5>No Cost EMI Available </h5>
                                                        </div>
                                                        <div className="app_p">
                                                            <p>Access quality medical services with ease through our No-Cost EMI option. Prioritize your health without worrying about upfront costs.</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={6}></Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="appoint_form">
                                <div className="appoint_card">
                                    <Formik
                                        initialValues={{
                                            name: '',
                                            email: '',
                                            phoneNumber: '',
                                            selectedService: '', // Include selectedService in the initial values
                                            selectedDate: '',
                                            message: '',
                                        }}
                                        validationSchema={Yup.object({
                                            name: Yup.string().required('Name is required'),
                                            email: Yup.string().email('Invalid email address').required('Email is required'),
                                            phoneNumber: Yup.string().required('Phone Number is required'),
                                            selectedService: Yup.string().required('Select a service'),
                                            selectedDate: Yup.date()
                                                .required('Select a date')
                                                .min(new Date(), 'Selected date cannot be in the past'), // Custom validation rule for the date
                                            message: Yup.string().required('Brief is required'),
                                        })}
                                        onSubmit={handleFormSubmit}
                                    >
                                        {(props) => { // Use props here instead of formikProps
                                            formikProps = props; // Assign props to formikProps for later use
                                            return (
                                                <Form ref={form} onSubmit={formikProps.handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Field type="text" placeholder="Name*" name="name" as={Form.Control} />
                                                        <ErrorMessage name="name" component="div" className="error-message" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Field type="email" placeholder="Your Email*" name="email" as={Form.Control} />
                                                        <ErrorMessage name="email" component="div" className="error-message" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Field type="text" placeholder="Phone Number*" name="phoneNumber" as={Form.Control} />
                                                        <ErrorMessage name="phoneNumber" component="div" className="error-message" />
                                                    </Form.Group>

                                                    <Form.Group className="mb-3">
                                                        <Form.Select name='doctor' value={selectedDoctor}
                                                            onChange={(e) => setSelectedDoctor(e.target.value)} >
                                                            <option selected>Choose Doctor (optional)</option>
                                                            <option>Dr. Shweta Mishra</option>
                                                            <option>Dr. Gaurav Pandey</option>
                                                            <option>Dr. Shubham Pandey</option>
                                                        </Form.Select>
                                                    </Form.Group>

                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <div className="relative-position">
                                                            <Field
                                                                type="text"
                                                                placeholder="Search for a service..."
                                                                name="selectedService"
                                                                as={Form.Control}
                                                                value={inputValue}
                                                                onChange={handleInputChange}
                                                            />
                                                            {showDropdown && inputValue.length > 0 && (
                                                                <div className="dropdown-menu">
                                                                    {filteredOptions.length > 0 ? ( // Check if there are filtered options
                                                                        filteredOptions.map((service, index) => (
                                                                            <div
                                                                                key={index}
                                                                                className="dropdown-item"
                                                                                onClick={() => handleSelectService(service)}
                                                                            >
                                                                                {service}
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className="dropdown-item">No matching services</div>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <ErrorMessage name="selectedService" component="div" className="error-message" />
                                                    </Form.Group>


                                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                                        <Field
                                                            type="date"
                                                            placeholder="Date*"
                                                            name="selectedDate"
                                                            as={Form.Control}
                                                        />
                                                        <ErrorMessage name="selectedDate" component="div" className="error-message" />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3">
                                                        <Field
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Brief Your Problem"
                                                            name="message"
                                                        />
                                                        <ErrorMessage name="message" component="div" className="error-message" />
                                                    </Form.Group>

                                                    <div className="appoint_btn">
                                                        <Button variant="primary" type="submit">
                                                            Make Appointment
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )
                                        }}
                                    </Formik>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div >
        </>
    );
}

export default Makeappointment;
