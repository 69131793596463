import React from 'react';
import { Container, Nav, Navbar, NavDropdown, Dropdown, DropdownButton, ButtonGroup, Button, Form } from 'react-bootstrap';
import logo from '../../assets/logo.png';
import './header.css';

const Otherheader = () => {
    return (
        <>
            <div className="otherHeader">
                <Navbar expand="lg" className="bg-body-tertiary">
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={logo} alt="logo" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/">Home</Nav.Link>
                                <Nav.Link href="/about">About</Nav.Link>
                                <NavDropdown title="Services" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="#action/3.1">
                                        <div className="mb-2">
                                            {['end'].map(
                                                (direction) => (
                                                    <DropdownButton
                                                        as={ButtonGroup}
                                                        key={direction}
                                                        id={`dropdown-button-drop-${direction}`}
                                                        drop={direction}
                                                        variant="secondary"
                                                        title={` Dr. Shweta Mishra`}
                                                    >
                                                        {['end'].map(
                                                            (direction) => (
                                                                <DropdownButton
                                                                    as={ButtonGroup}
                                                                    key={direction}
                                                                    id={`dropdown-button-drop-${direction}`}
                                                                    drop={direction}
                                                                    variant="secondary"
                                                                    title={`Surgical`}
                                                                >
                                                                    <Dropdown.Item href='/services/facelift' eventKey="12">Face Lift</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/necklift' eventKey="14">Neck Lift</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/browlift' eventKey="15">Brow Lift</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/transplant' eventKey="18">Haip Transplant</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/otoplasty' eventKey="18">Otoplasty</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/grafting' eventKey="21">Facial Fat Gratting</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/eyelid' eventKey="13">Eyelid Surgery (BLEPHAROPLASTY)</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/chin' eventKey="16">Chin Surgery (MENTOPLASTY)</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/cheeks' eventKey="16">Cheeks Fat Removal</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/dimple' eventKey="19">Dimple Creation</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/earlube' eventKey="20">Ear Lobe Repair</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/feminization' eventKey="19">Facial Feminization Surgery</Dropdown.Item>
                                                                </DropdownButton>
                                                            ),
                                                        )}
                                                        <br />
                                                        {['end'].map(
                                                            (direction) => (
                                                                <DropdownButton
                                                                    as={ButtonGroup}
                                                                    key={direction}
                                                                    id={`dropdown-button-drop-${direction}`}
                                                                    drop={direction}
                                                                    variant="secondary"
                                                                    title={`Non - Surgical`}
                                                                >
                                                                    <Dropdown.Item href='/services/dermal' eventKey="2">Dermal Fillers</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/resurfacing' eventKey="7">Skin Resurfacing Procedure</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/laser' eventKey="7">Laser Resurfacing</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/microneeding' eventKey="7">Microneeding</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/peels' eventKey="5">Chemical Peels</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/regrowth' eventKey="9">Hair Re-Growth Treatment Therapy</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/hydrofacial' eventKey="4">HydraFacial</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/laser' eventKey="8">Laser Hair Reduction</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/pigmentation' eventKey="8">Pigmentation</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/rejuvention' eventKey="8">Skin Rejuvention</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/tattoo' eventKey="8">Tattoo Removal</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/carbon' eventKey="8">Carbon Laser Peel</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/thread' eventKey="8">Thread Lift</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/liquid' eventKey="8">Liquid Facelift</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/bioprp' eventKey="8">BIO PRP Therapy</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/AWI' eventKey="1">Anti-Wrinkle Injection</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/boosters' eventKey="3">Injectable skin Boosters</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/meso' eventKey="6">MesoTherapy</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/micro' eventKey="10">MicroDermadrasion</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/makeup' eventKey="11">Semi- Permanent Makeup</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/lip' eventKey="17">Lip Surgery</Dropdown.Item>
                                                                    <Dropdown.Item href='/services/botox' eventKey="17">Botox</Dropdown.Item>
                                                                </DropdownButton>
                                                            ),
                                                        )}
                                                    </DropdownButton>
                                                ),
                                            )}
                                        </div>
                                    </NavDropdown.Item>
                                    <NavDropdown.Item href="#action/3.2">
                                        <div className="mb-2">
                                            {['end'].map(
                                                (direction) => (
                                                    <DropdownButton
                                                        as={ButtonGroup}
                                                        key={direction}
                                                        id={`dropdown-button-drop-${direction}`}
                                                        drop={direction}
                                                        variant="secondary"
                                                        title={` Dr. Gaurav Pandey`}
                                                    >
                                                        <Dropdown.Item eventKey="1" href="/gservices/asthma">Asthma</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" href="/gservices/copd">COPD</Dropdown.Item>
                                                        <Dropdown.Item eventKey="3" href="/gservices/allergies">Allergies</Dropdown.Item>
                                                        <Dropdown.Item eventKey="4" href="/gservices/tb">TB</Dropdown.Item>
                                                        <Dropdown.Item eventKey="5" href="/gservices/cough">Cough and cold</Dropdown.Item>
                                                        <Dropdown.Item eventKey="6" href="/gservices/chest">Chest infection</Dropdown.Item>
                                                        <Dropdown.Item eventKey="7" href="/gservices/snoring">Snoring</Dropdown.Item>
                                                        <Dropdown.Item eventKey="8" href="/gservices/sleep">Sleep disturbances</Dropdown.Item>
                                                        <Dropdown.Item eventKey="9" href="/gservices/respiratory">Respiratory issues</Dropdown.Item>
                                                        <Dropdown.Item eventKey="10" href="/gservices/sinusitis">Sinusitis</Dropdown.Item>
                                                        <Dropdown.Item eventKey="11" href="/gservices/eosinophilia">Eosinophilia</Dropdown.Item>
                                                        <Dropdown.Item eventKey="12" href="/gservices/diabetes">Diabetes</Dropdown.Item>
                                                        <Dropdown.Item eventKey="13" href="/gservices/neuropathy">Diabetic neuropathy</Dropdown.Item>
                                                        <Dropdown.Item eventKey="14" href="/gservices/hypertension">Hypertension </Dropdown.Item>
                                                        <Dropdown.Item eventKey="15" href="/gservices/ecg">ECG</Dropdown.Item>
                                                        <Dropdown.Item eventKey="16" href="/gservices/pft">PFT</Dropdown.Item>
                                                        <Dropdown.Item eventKey="17" href="/gservices/sugar">SUGAR PROFILE</Dropdown.Item>
                                                    </DropdownButton>
                                                ),
                                            )}
                                        </div>
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/gallery">Gallery</Nav.Link>
                                <Nav.Link href="/contact">Contact</Nav.Link>
                            </Nav>
                            <Form className="d-flex">
                                <a href="/make/appoint"><Button className='btnbtn'>Book an Appointment</Button><br /><small>No Cost EMI available</small></a>
                            </Form>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    );
}

export default Otherheader;
