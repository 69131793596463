import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gallery1 from '../../../assets/gallery1.png'
import gallery2 from '../../../assets/gallery2.png'
import gallery3 from '../../../assets/gallery3.png'
import gallery4 from '../../../assets/gallery4.png'
import gallery5 from '../../../assets/gallery5.png'
import gallery6 from '../../../assets/gallery6.png'

const Gallery = () => {
    return (
        <>
            <div className="gallery my-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='Button_healthy mb-5'>
                                <span className='lets'>Gallery</span>
                            </div>
                            <div className="main_head mt-5">
                                <h2>Capturing Moments of Transformation</h2>
                            </div>
                            <div className="main_p mt-3">
                                <p>Explore our gallery to witness the remarkable transformations <br/> and the journey to renewed beauty.</p>
                            </div>
                        </Col>
                    </Row>
                    <div className="gallery_photo my-5">
                        <Row>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery1} alt="" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery2} alt="" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery3} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="gallery_photo mt-4">
                        <Row>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery4} alt="" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery5} alt="" />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="gallery_img">
                                    <img src={gallery6} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Gallery;
