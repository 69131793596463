import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";

const Contactbanner = () => {
    return (
        <>
            <div className="contact_banner">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="appoint_left">
                                <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Contact </p>
                            </div>

                            <div className="gallery_head text-white">
                                <h1 className='pt-5'>Reach Out for Your <br /> Health and Beauty Needs</h1>
                                <p className='pt-2'>Connect with us to schedule your appointment or inquire <br /> about our services. Your path to well-being starts here.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Contactbanner;
