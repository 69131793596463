import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import a1 from '../../../assets/afterbefore/a1.png'
import a2 from '../../../assets/afterbefore/a2.png'
import a4 from '../../../assets/afterbefore/a4.png'
import a5 from '../../../assets/afterbefore/a5.png'
import a6 from '../../../assets/afterbefore/a6.png'
import a7 from '../../../assets/afterbefore/a7.jpg'
import a8 from '../../../assets/afterbefore/a8.jpg'
import a9 from '../../../assets/afterbefore/a9.jpg'
import a10 from '../../../assets/afterbefore/a10.jpg'
import a11 from '../../../assets/afterbefore/a11.JPG'
import a12 from '../../../assets/afterbefore/a12.JPG'
import a13 from '../../../assets/afterbefore/a13.jpg'
import a14 from '../../../assets/afterbefore/a14.JPG'
import a15 from '../../../assets/afterbefore/a15.jpeg'
import a16 from '../../../assets/afterbefore/a16.jpg'
import a17 from '../../../assets/afterbefore/a17.jpg'
import a18 from '../../../assets/afterbefore/a18.png'
import a19 from '../../../assets/afterbefore/a19.png'
import a20 from '../../../assets/afterbefore/a20.png'
import a21 from '../../../assets/afterbefore/a21.png'

const Afterbefore = () => {
    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className="after_before my-5">
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className='Button_healthy mb-5'>
                                <span className='lets'>See the Changes: Before & After</span>
                            </div>
                            <div className="main_head mt-5">
                                <h2></h2>
                            </div>
                            <div className="main_p mt-3">
                                <p>Explore the amazing results of our treatments with side-by-side images. Watch as our <br />
                                    procedures breathe new life into your appearance, making you <br /> look and feel your best.</p>
                            </div>
                        </Col>
                    </Row>

                    <div className="gallery_slider">
                        <Slider {...settings}>
                            <div className='improve_images'>
                                <img src={a1} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a2} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a4} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a5} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a6} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a7} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a8} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a9} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a10} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a11} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a12} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a13} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a20} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a15} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a16} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a17} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a18} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a19} alt="" />
                            </div>
                            <div className='improve_images'>
                                <img src={a21} alt="" />
                            </div>

                        </Slider>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Afterbefore;
