import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";

const Doctorbanner = () => {
    return (
        <>
            <div className="doctor_banner">
                <Container>
                    <div className="appoint_left">
                        <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Doctor </p>
                    </div>

                    <div className="gallery_head text-white">
                        <h1 className='pt-5'>Meet Our Skilled <br/> Practitioners </h1>
                        <p className='pt-2'>Experience a dedicated team of specialized doctors <br/> committed to your health and beauty journey.</p>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Doctorbanner;
