import React from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { LiaGreaterThanSolid } from "react-icons/lia";

const Gallerybanner = () => {
    return (
        <>
            <div className="gallery_banner">
                <Container>
                    <div className="appoint_left">
                        <p className='lets'>Home <LiaGreaterThanSolid className='lets_icon' /> Gallery </p>
                    </div>

                    <div className="gallery_head text-white">
                        <h1 className='pt-5'>Capturing Moments of <br/> Transformation</h1>
                        <p className='pt-2'>Explore our gallery to witness <br/> the remarkable transformations and the journey to renewed beauty.    </p>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Gallerybanner;
