import React from 'react';
import whatsapp_img from '../../assets/whatsapp.png'
import instagram_img from '../../assets/instagram.png'
import youtube_img from '../../assets/youtube.png'
import './whatsapp.css'

const Whatsapp = () => {
    return (
        <>
            <div className="whatsapp_float">
                <a href="https://wa.me/918595941055" target='_blank'><img src={whatsapp_img} alt=""  className='mt-2'/></a> <br />
                <a href="https://www.instagram.com/sharvaclinic/" target='_blank'><img src={instagram_img} alt="" className='mt-2'/></a> <br />
                <a href="https://www.youtube.com/@Sharvaclinic" target='_blank'><img src={youtube_img} alt="" className='mt-2'/></a>
            </div>
        </>
    );
}

export default Whatsapp;
